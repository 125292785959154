import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from '@remix-run/react'
import { Search } from 'lucide-react'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Form, FormField, FormItem, FormLabel } from '../ui/form'
import { Input } from '../ui/input'

const SearchUserInputFormSchema = z.object({
  query: z.string(),
})
type SearchUserInputForm = z.infer<typeof SearchUserInputFormSchema>

type UserSearchProps = {
  searchQuery?: string | null
}

export const UsersSearch = ({ searchQuery }: UserSearchProps) => {
  const form = useForm<SearchUserInputForm>({
    resolver: zodResolver(SearchUserInputFormSchema),
    defaultValues: {
      query: searchQuery ?? '',
    },
  })

  const handleSubmit = useCallback(
    (data: SearchUserInputForm) => {
      form.setValue('query', data.query)
    },
    [form],
  )

  const navigate = useNavigate()
  const handleNavigateToSearchPage = useCallback(
    (query: string) => {
      navigate(`/user/search?q=${query}`)
    },
    [navigate],
  )

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormField
          control={form.control}
          name="query"
          render={({ field }) => (
            <FormItem className="bg-husthere-disabled flex items-center space-y-0 rounded-full px-3 py-1">
              <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
              <Input
                id="user-search-query-input"
                className="border-0 bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
                {...field}
                placeholder="Search Traveler"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleNavigateToSearchPage(field.value)
                  }
                }}
              />
              <FormLabel className="hidden" htmlFor="user-search-query-input">
                Search users input field
              </FormLabel>
            </FormItem>
          )}
        />
      </form>
    </Form>
  )
}
